import React, { Component, useEffect } from 'react';

const Layout = ({children}) =>{
  return (
    <div>
      {children}
    </div>
  );
}

export default Layout